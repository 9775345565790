import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import HomePage from "./pages/HomePage";
import AboutPage from "./pages/AboutPage";

import "./App.css";

function App() {
	return (
		<Router>
			<SnackbarProvider maxSnack={3}>
				<Switch>
					<Route path="/about">
						<AboutPage />
					</Route>
					<Route path="/">
						<HomePage />
					</Route>
				</Switch>
			</SnackbarProvider>
		</Router>
	);
}

export default App;
