import React from "react";
import "../App.css";
import "./About.css";

const AboutPage = () => {
	return (
		<div className="App">
			<header className="App-header">
				<div className="about-page">
                    <p className="page-title">About Us</p>
					<p>
						We’re an INDEPENDENT project powered by{" "}
						<a className="bold" href="https://goodhood.sg">GoodHood.SG</a> to allow candidates
						and residents within a constituency to interact directly and
						privately.
					</p>
					<p>
						We were independently approached by candidates from multiple parties
						whether GoodHood.SG could be used to connect with mobile-verified
						residents of a constituency, however, we felt that introducing
						politics into the main feed of each neighbourhood would be very
						divisive.
					</p>
					<p>
						Hence, we came up with (whorunwhere.com), and temporary pages for
						each party running within a constituency (available only within the
						app), so that mobile-verified residents can easily access relevant
						content across all parties, and at the same time engage with
						candidates so that residents can better understand their candidates
						before making the most informed choices.
					</p>
					<p>
						Any political content on the main neighbourhood feeds will <span className="bold">NOT BE
						TOLERATED</span>, and will result in an immediate ban without warning. This
						includes posts made by candidates who are NOT residents of the area.
					</p>
					<p>
						<a className="bold" href="https://goodhood.sg">GoodHood.SG</a> <span className="bold">DOES NOT</span> have any
						affiliations with, or inclinations towards any party.
					</p>
				</div>
			</header>
		</div>
	);
};

export default AboutPage;
