import React from "react";
import axios from "axios";
import { useSnackbar } from "notistack";

import "../App.css";

function HomePage() {
	const [postalCode, setPostalCode] = React.useState("");
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();

	function handleSubmit(event) {
    closeSnackbar()
		event.preventDefault();
		if (postalCode.length === 6) {
			axios
				.get(`https://goodhood.sg/api/constituency/${postalCode}`)
				.then((res) => {
					const queryName = res.data?.queryName;
					if (!queryName) {
						throw new Error("shit");
					}
					window.location.href = `https://goodhood.sg/ge2020/${queryName}`;
				})
				.catch((er) => {
					enqueueSnackbar(
            "Sorry, we can't find any constituency in this postal code, please check and try again.",
            {
              variant: "error"
            }
					);
				});
		} else {
			enqueueSnackbar("Postal code must be 6 characters long.", {
        variant: "warning"
      });
		}
	}

	function handleChange(event) {
		const regex = /^[0-9\b]+$/;
    closeSnackbar()
		const newVal = event.target.value;
		if (newVal === "" || (regex.test(newVal) && newVal.length <= 6)) {
			setPostalCode(newVal);
		}
	}

	return (
		<div className="App">
			<header className="App-header">
				<p className="title">Connect with verified constituents & candidates</p>

				<form onSubmit={handleSubmit}>
					<div className="container">
						<div className="content">
							<input
								value={postalCode}
								onChange={handleChange}
								type="text"
								inputmode="decimal"
								inputMode="decimal"
								placeholder="Enter postal code"
							></input>
							<button className="submit-button" type="submit">
								<img src="/icons/search.png" alt="search" />
							</button>
						</div>
					</div>
				</form>
				<div className="caption">
					<img
						style={{ height: 30, marginRight: 6 }}
						src="/icon.png"
						alt="WhoRunWhere"
					/>
					<span>with official mapping data from data.gov.sg</span>
				</div>
			</header>
			<div className="footer">
				<a className="about-us" href="/about">
					about us
				</a>
				<a href="https://www.instagram.com/goodhood.sg/">
					<img className="social-icon" src="/instagram.png" alt="Instagram" />
				</a>
				<a href="https://www.facebook.com/goodhood.sg/">
					<img className="social-icon" src="/facebook.png" alt="facebook" />
				</a>
			</div>
		</div>
	);
}

export default HomePage;
